.divComponente {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  background-color: #D1D1D1;
}

.formComponente {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 16px;
  border-radius: 8px;
  margin-right: 16px;
  margin-left: 16px;
}

.formComponenteCheckbox {
  display: flex;
  flex-direction: column;
  margin-right: 16px;
  margin-left: 16px;
}

.checkboxDisabled {
  pointer-events: none;
  opacity: 0.5;
}
  

.titleComponente {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  color: #1d3054
}

.descriptionComponente {
  font-size: 14px;
  line-height: 20px;
  color: #4d4d4d
}

.gridComponente, .gridComponenteRow {
  display: flex;
  gap: 8px;
  width: 100%;
}

.gridComponente {
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
}

.gridComponenteRow {
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
}

.gridComponenteRadio {
  display: flex;
  flex-direction: column;
  gap: 0;
  width: 100%;
  margin-bottom: 16px;
}

.gridComponenteNumber {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 60%;
  margin-bottom: 16px;
}

.inputLabel {
  font-size: 14px;
}

.titleTypography {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  color: #1d3054;
}

.titleTooltip {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  color: #1d3054;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.optionTooltip {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

}

.tooltipOutlined {
  font-size: 16px;
  cursor: pointer;
  color: #4D4D4D;
}

.textField {
  width: 100%;
  height: 40px;
  border: 1px;
  border-radius: 4px;
}

.textFieldDisabled {
  width: 100%;
  height: 40px;
  border: 1px;
  border-radius: 4px;
  background-color: #E8E8E8;
}

.textFieldDisabled input {
  color: #848484
  ;
}

.textFieldOutraNacionalidade {
  margin-bottom: 8px;
  }
  
.divButtons {
  margin: 16px 16px 0px 16px;
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.buttonSaveNext, .buttonSaveExit, .buttonDelete {
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 8px;
  padding: 16px;
  width: 100%;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  height: 52px;
  text-transform: uppercase;
}

.buttonSaveNext {
  background-color:#003DA5 ;
  color: #fff;
}

.buttonSaveNext:hover {
  background-color:#002361 ;
  color: #fff;
}

.buttonSaveExit {
  color: #003DA5;
  border: 2px solid #003DA5;
  background-color: #fff;
}

.buttonSaveExit:hover {
  color: #003DA5;
  background-color: #c7c7c7;
}

.buttonDelete {
  color: #B91C1C;
  border: 2px solid #B91C1C;
  background-color: #fff;
}

.buttonDelete:hover {
  color: #B91C1C;
  background-color: #c7c7c7;
}

.asterisk {
  color: red;
}

.buttonAttach {
  display: flex;
  padding: 16px 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
  color: #1d3054;
  border: 1px dashed #B1B3B3;
  margin: 16px;
}

.buttonAttach h2 {
  font-size: 20px;
}

.buttonAttach p {	
  font-size: 14px;
}

.card {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: #fff;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0px 2px 4px -2px rgba(77, 77, 77, 0.06), 0px 4px 8px -2px rgba(77, 77, 77, 0.10);
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  margin: 16px;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e8e8e8;
  height: 40px;
  width: 40px;
  border-radius: 4px;
}

.cardTitle {
  font-size: 14px;
  font-weight: 700;
  color: #1d3054;
}

.cardSubtitle {
  font-size: 12px;
  color: #4d4d4d;
}

.chevronContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
}

.cardClickable {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.textContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-left: 8px;
  margin-right: 8px;
}

.modalContainer {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  width: 100%;
  text-align: center;
}

.modalTitle {
  font-size: 18px;
  font-weight: 700;
  color: #B91C1C;
  margin-top: 8px;
  margin-bottom: 8px;
}

.modalSubtitle {
  font-size: 14px;
  margin-bottom: 20px;
  color: #4D4D4D;
}

.modalButtons {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}

.modal-button-cancel {
  background-color: #f0f0f0;
  color: #1D3054;
  border: #1D3054 2px solid;
  width: 100%;
  border-radius: 8px;
  padding: 16px;
  font-weight: 700;
  font-size: 16px;
}

.modal-button-delete {
  background-color: #B91C1C;
  color: white;
  border: none;
  width: 100%;
  border-radius: 8px;
  padding: 16px;
  font-weight: 700;
  font-size: 16px;
}

.selectFieldDisabled {
  background-color: #E8E8E8;
}